import React, {useState, useContext} from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import {AuthContext} from "../../context/Auth/AuthContext";
import ContactSelect from "../ContactSelect";
import Typography from "@material-ui/core/Typography";
import {toast} from "react-toastify";

const ForwardMessageModal = ({ modalOpen, onClose, message }) => {
	const [loading, setLoading] = useState(false);
	const [selectedContactIds, setSelectedContactIds] = useState([]);
	const { user } = useContext(AuthContext);

	const handleClose = () => {
		onClose();
		setSelectedContactIds([])
	};

	const handleSaveTicket = async e => {
		e.preventDefault();
		if (!message) return;
		setLoading(true);
		try {
			await api.post(`/messages/forward/${message.id}`, {
				contacts: selectedContactIds,
				log: {
					descricao: `O usuário ${user?.name} encaminhou a mensagem "${message.body}" do ticket ${message.ticketId} para os contatos ${selectedContactIds.join(",")}`,
					origem: 'Ticket',
					userId: user?.id,
					ticketId: message.ticketId,
					messageId: message.id,
				}
			});

			toast.success("Mensagem encaminhada com sucesso");

			handleClose();

			setLoading(false);
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

	return (
		<Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
			<form onSubmit={handleSaveTicket}>
				<DialogTitle id="form-dialog-title">
					Encaminhar mensagem
				</DialogTitle>
				<DialogContent dividers>
					<Typography
						component="p"
						variant="body2"
						color="textPrimary"
					>
						Deseja encaminhar a mensagem a seguir para quais contatos?
					</Typography>
					<br/>
					<Typography
						component="p"
						variant="body2"
						color="textPrimary"
						style={{fontSize: "12pt", fontStyle: "italic"}}
					>
						"{message.body}"
					</Typography>
					<br/>
					<ContactSelect
						selectedContactIds={selectedContactIds}
						onChange={(selectedIds) => {
							setSelectedContactIds(selectedIds);
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("transferTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="submit"
						color="primary"
						loading={loading}
					>
						Enviar
					</ButtonWithSpinner>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default ForwardMessageModal;
