import React, {Fragment, useContext, useEffect, useState} from "react"

import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import {makeStyles} from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography";

import useTickets from "../../hooks/useTickets"

import {AuthContext} from "../../context/Auth/AuthContext";

import {i18n} from "../../translate/i18n";

import Chart from "./Chart"

import ChartColaborador from "./ChartColaborador";
import ChartClientes from "./ChartClientes";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import UserSelectDashboard from "../../components/UserSelectDashboard";
import QueueSelectDashboard from "../../components/QueueSelectDashboard";
import Stats from "./Stats";
import UsersDashboard from "./UsersDashboard";
import ChartQueue from "./ChartQueue";

import ChatIcon from '@material-ui/icons/Chat';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import ChartNPS from "./ChartNPS";

const isMobile = (typeof window.orientation) === "number";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    fixedHeightPaper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: 240,
    },
    fixedHeightPaperMaior: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: 380,
    },
    customFixedHeightPaper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "hidden",
        flexDirection: "column",
        height: 120,
        backgroundColor: "#0078a8",
        color: "white",
        position: 'relative', // Adiciona posição relativa
    },
    iconStyle: {
        position: 'absolute', // Torna o ícone posicionado dentro do papel
        top: '0.5em', // Ajuste para melhor posição vertical
        right: '0.5em', // Ajuste para melhor posição horizontal
        fontSize: isMobile ? "1.3em" : '4.5em', // Tamanho do ícone
    },
    customFixedHeightPaperLg: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: "100%",
    },
    groupGridTitle: {
        fontStyle: "italic",
        color: "#6a6a6a"
    }
}))

const Dashboard = () => {
    const classes = useStyles()

    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const {user} = useContext(AuthContext);
    const [mili, setMili] = useState(new Date().getMilliseconds());
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [selectedQueueIds, setSelectedQueueIds] = useState([]);
    const [firstDate, setFirstDate] = useState(firstDay);
    const [lastDate, setLastDate] = useState(lastDay);
    var userQueueIds = [];

    if (user.queues && user.queues.length > 0) {
        userQueueIds = user.queues.map(q => q.id);
    }

    useEffect(() => {
        if (user?.profile?.toLowerCase() !== "admin")
            window.location.href = "/tickets";
    }, [user]);

    const GetTickets = (status, showAll, withUnreadMessages) => {

        const {count} = useTickets({
            status: status,
            showAll: showAll,
            withUnreadMessages: withUnreadMessages,
            queueIds: selectedQueueIds.length > 0 ? JSON.stringify(selectedQueueIds) : selectedQueueIds > 0 ? JSON.stringify(selectedQueueIds) : JSON.stringify(userQueueIds)
            // firstDate: firstDate.toISOString(),
            // lastDate: lastDate.toISOString()
        });
        return count;
    }

    return (
        <div>
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <Fragment>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    // clearable
                                    fullWidth
                                    inputVariant="outlined"
                                    placeholder="20/03/2023"
                                    value={firstDate}
                                    onChange={(date)=>{
                                        setFirstDate(date)
                                        setMili(new Date().getMilliseconds())
                                    }}
                                    // minDate={new Date()}
                                    format="dd/MM/yyyy"
                                    label="De"
                                    minDateMessage={"Data não pode ser anterior a hoje"}
                                    // maxDateMessage={"Dia do envio"}
                                    invalidDateMessage={"Formato de data inválido"}
                                />
                            </MuiPickersUtilsProvider>
                        </Fragment>
                    </Grid>

                    <Grid item xs={3}>
                        <Fragment>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    // clearable
                                    fullWidth
                                    inputVariant="outlined"
                                    placeholder="20/03/2023"
                                    value={lastDate}
                                    onChange={(date)=>{
                                        setLastDate(date)
                                        setMili(new Date().getMilliseconds())
                                    }}
                                    // minDate={new Date()}
                                    format="dd/MM/yyyy"
                                    label="Até"
                                    minDateMessage={"Data não pode ser anterior a hoje"}
                                    // maxDateMessage={"Dia do envio"}
                                    invalidDateMessage={"Formato de data inválido"}
                                />
                            </MuiPickersUtilsProvider>
                        </Fragment>
                    </Grid>

                    <Grid item xs={3}>
                        <UserSelectDashboard
                            selectedUserIds={selectedUserIds}
                            style={{marginTop: 0}}
                            queueId={0}
                            all={true}
                            onChange={values => {
                                setSelectedUserIds(values)
                                setMili(new Date().getMilliseconds())
                            }}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <QueueSelectDashboard
                            selectedQueueIds={selectedQueueIds}
                            onChange={values => {
                                setSelectedQueueIds(values)
                                setMili(new Date().getMilliseconds())
                            }}
                            style={{marginTop: 0}}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography component="h5" variant="h5" className={classes.groupGridTitle}>
                            Tickets
                        </Typography>
                    </Grid>

                    <Grid item xs={isMobile ? 6 : 4}>
                        <Paper className={classes.customFixedHeightPaper}>
                            <ChatIcon className={classes.iconStyle}/>
                            <Typography component="h3" variant="h6" paragraph>
                                {isMobile ? "Atendendo" : "Em atendimento"}
                            </Typography>
                            <Grid item>
                                <Typography component="h1" variant="h4">
                                    {GetTickets("open", "true", "false").toLocaleString('pt-BR')}
                                </Typography>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={isMobile ? 6 : 4}>
                        <Paper className={classes.customFixedHeightPaper}>
                            <QueryBuilderIcon className={classes.iconStyle} />
                            <Typography component="h3" variant="h6" paragraph>
                                Em espera
                            </Typography>
                            <Grid item>
                                <Typography component="h1" variant="h4">
                                    {GetTickets("pending", "true", "false").toLocaleString('pt-BR')}
                                </Typography>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={isMobile ? 6 : 4}>
                        <Paper className={classes.customFixedHeightPaper}>
                            <PlaylistAddCheckIcon className={classes.iconStyle} />
                            <Typography component="h3" variant="h6" paragraph>
                                {i18n.t("dashboard.messages.closed.title")}s
                            </Typography>
                            <Grid item>
                                <Typography component="h1" variant="h4">
                                    {GetTickets("closed", "true", "false").toLocaleString('pt-BR')}
                                </Typography>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.fixedHeightPaper}>
                            <Chart
                                key={mili}
                                firstDate={firstDate}
                                lastDate={lastDate}
                                queueId={selectedQueueIds}
                                userId={selectedUserIds}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 6}>
                        <Paper className={classes.fixedHeightPaperMaior}>
                            <ChartColaborador
                                key={mili}
                                firstDate={firstDate}
                                lastDate={lastDate}
                                queueId={selectedQueueIds}
                                userId={selectedUserIds}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 6}>
                        <Paper className={classes.fixedHeightPaperMaior}>
                            <ChartClientes
                                key={mili}
                                firstDate={firstDate}
                                lastDate={lastDate}
                                queueId={selectedQueueIds}
                                userId={selectedUserIds}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 6}>
                        <Paper className={classes.fixedHeightPaperMaior}>
                            <ChartQueue
                                key={mili}
                                firstDate={firstDate}
                                lastDate={lastDate}
                                queueId={selectedQueueIds}
                                userId={selectedUserIds}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 6}>
                        <Paper className={classes.fixedHeightPaperMaior}>
                            <ChartNPS
                                key={mili}
                                firstDate={firstDate}
                                lastDate={lastDate}
                                queueId={selectedQueueIds}
                                userId={selectedUserIds}
                            />
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Stats
                            key={mili}
                            firstDate={firstDate}
                            lastDate={lastDate}
                            queueId={selectedQueueIds}
                            userId={selectedUserIds}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography component="h5" variant="h5" className={classes.groupGridTitle}>
                            Usuários
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.customFixedHeightPaperLg}>
                            <UsersDashboard
                                firstDate={firstDate}
                                lastDate={lastDate}
                                queueId={selectedQueueIds}
                                userId={selectedUserIds}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Dashboard