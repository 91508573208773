import React, {useContext, useEffect, useState} from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { MoreVert, Replay } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import {toast} from "react-toastify";
import ConfirmationModal from "../ConfirmationModal";
import AcceptTicketWithouSelectQueue from "../AcceptTicketWithoutQueueModal";
import useQueues from "../../hooks/useQueues";

const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(1),
		},
	},
}));

const TicketActionButtons = ({ ticket }) => {
	const classes = useStyles();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const { user } = useContext(AuthContext);
	const [generateProtocol, setGenerateProtocol] = useState(null);
	const [greetingProtocolGroup, setGreetingProtocolGroup] = useState(null);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [queues, setQueues] = useState([]);
	const [acceptTicketWithouSelectQueueOpen, setAcceptTicketWithouSelectQueueOpen] = useState(false);
	const { findAll: findAllQueues } = useQueues();

	useEffect(() => {
		const fetchSession = async () => {
			try {
				const { data } = await api.get("/settings");

				const ha = data.find(s => s.key === 'generateProtocol')
				if (ha?.value)
					setGenerateProtocol(ha.value)

				const gpg = data.find(s => s.key === 'greetingProtocolGroup')
				if (gpg?.value)
					setGreetingProtocolGroup(gpg.value)
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, []);

	useEffect(() => {
		const loadQueues = async () => {
			const list = await findAllQueues();
			setQueues(list);
		}
		loadQueues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOpenTicketOptionsMenu = e => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseTicketOptionsMenu = e => {
		setAnchorEl(null);
	};

	const handleOpenConfirmationModal = e => {
		setConfirmationOpen(true);
	};

	const handleOpenAcceptTicketWithouSelectQueue = () => {
		setAcceptTicketWithouSelectQueueOpen(true);
	};

	const handleCloseConfirmationModal = async e => {
		setLoading(true);
		setConfirmationOpen(false);
		try {
			setTimeout(async (e)=>{
				await api.put(`/tickets/${ticket.id}`, {
					status: 'closed',
					userId: user.id || null,
					log: {
						descricao: `O usuário ${user?.name} mudou o status do ticket ${ticket.id} para closed usando os botões de ação`,
						origem: 'Ticket',
						userId: user?.id,
						ticketId: ticket.id
					}
				});

				setLoading(false);
				history.push("/tickets");
				toast.success(`Ticket finalizado!`);
			}, 4500);
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	}

	const handleEnviarProtocolo = async ()=>{
		if (generateProtocol === "enabled" && ((ticket.isGroup && greetingProtocolGroup === "enabled") || !ticket.isGroup)) {
			await api.post(`/protocolos`, {
				contactId: ticket.contactId,
				ticketId: ticket.id
			});
			toast.success(`Protocolo gerado com sucesso! Seu ticket será finalizado em 5 segundos...`);
		}
	}

	const handleUpdateTicketStatus = async (e, status, userId) => {
		setLoading(true);
		if (status === "closed" && generateProtocol === "enabled" && ((ticket.isGroup && greetingProtocolGroup === "enabled") || !ticket.isGroup)) {
			handleOpenConfirmationModal()
		} else {
			try {
				await api.put(`/tickets/${ticket.id}`, {
					status: status,
					userId: userId || null,
					log: {
						descricao: `O usuário ${user?.name} mudou o status do ticket ${ticket.id} contato ${ticket.contact?.name} de ${ticket.status} para ${status} usando os botões de ação`,
						origem: 'Ticket',
						userId: user?.id,
						ticketId: ticket.id,
						contactId: ticket.contactId
					}
				});

				setLoading(false);
				if (status === "open") {
					history.push(`/tickets/${ticket.id}`);
				} else {
					history.push("/tickets");
				}
			} catch (err) {
				setLoading(false);
				toastError(err);
			}
		}
	};

	const handleAcepptTicket = async id => {
		setLoading(true);
		try {
			const { data: tick } = await api.put(`/tickets/${id}`, {
				status: "open",
				userId: user?.id,
			});

			await api.post("/log", {
				log: {
					descricao: `O usuário ${user?.name} aceitou o ticket(${tick.id}), fila(${tick.queue?.name}), conexão(${tick.whatsapp?.name}) do contato ${tick.contact?.name}`,
					origem: 'Ticket',
					userId: user?.id,
					ticketId: tick.id,
					contactId: tick.contact?.id,
					queueId: tick.queue?.id,
					whatsappId: tick.whatsapp?.id
				}
			});
		} catch (err) {
			setLoading(false);
			toastError(err);
		}

		setLoading(false);
		history.push(`/tickets/${id}`);
	};

	return (
		<>
			<div className={classes.actionButtons}>
				{ticket.status === "closed" && (
					<ButtonWithSpinner
						loading={loading}
						startIcon={<Replay />}
						size="small"
						onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
					>
						{i18n.t("messagesList.header.buttons.reopen")}
					</ButtonWithSpinner>
				)}
				{ticket.status === "open" && (
					<>
						<ButtonWithSpinner
							loading={loading}
							startIcon={<Replay />}
							size="small"
							onClick={e => handleUpdateTicketStatus(e, "pending", null)}
						>
							{i18n.t("messagesList.header.buttons.return")}
						</ButtonWithSpinner>
						<ButtonWithSpinner
							loading={loading}
							size="small"
							variant="contained"
							color="primary"
							onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)}
						>
							{i18n.t("messagesList.header.buttons.resolve")}
						</ButtonWithSpinner>
						<IconButton onClick={handleOpenTicketOptionsMenu}>
							<MoreVert />
						</IconButton>
						<TicketOptionsMenu
							ticket={ticket}
							anchorEl={anchorEl}
							menuOpen={ticketOptionsMenuOpen}
							handleClose={handleCloseTicketOptionsMenu}
						/>
					</>
				)}
				{((ticket.status === "pending" || ticket.status === "bot") && (ticket.queue !== null || queues.length === 0)) && (
					<ButtonWithSpinner
						loading={loading}
						size="small"
						variant="contained"
						color="primary"
						onClick={e => handleAcepptTicket(ticket.id)}
					>
						{i18n.t("messagesList.header.buttons.accept")}
					</ButtonWithSpinner>
				)}
				{((ticket.status === "pending" || ticket.status === "bot") && queues.length > 0 && (ticket.queue === null || ticket.queue === undefined)) && (
					<ButtonWithSpinner
						color="secondary"
						variant="contained"
						// className={classes.acceptButton}
						size="small"
						loading={loading}
						onClick={e => handleOpenAcceptTicketWithouSelectQueue()}
					>
						{i18n.t("ticketsList.buttons.acceptBeforeBot")}
					</ButtonWithSpinner>
				)}
			</div>
			<AcceptTicketWithouSelectQueue
				modalOpen={acceptTicketWithouSelectQueueOpen}
				onClose={(e) => setAcceptTicketWithouSelectQueueOpen(false)}
				ticketId={ticket.id}
			/>
			<ConfirmationModal
				title={"Ticket finalizado!"}
				open={confirmationOpen}
				onClose={handleCloseConfirmationModal}
				onConfirm={handleEnviarProtocolo}
			>
				{" Deseja gerar e enviar um protocolo de atendimento para esse ticket?"}
			</ConfirmationModal>
		</>
	);
};

export default TicketActionButtons;
